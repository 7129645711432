_cruxUtils._cruxLocale = {
"crux.no.bc.message":"Aucune vue d\u2019ensemble disponible. Cliquez sur l\u2019enregistrement pour afficher plus de d\u00e9tails",
"crm.appointments.status.markcompleted1": "Marquer comme termin\u00e9",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">Tous les</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}\u00a0</span><span class=\"dIB vaM\">{1} dans cet affichage ont \u00e9t\u00e9 s\u00e9lectionn\u00e9s.</span>",
"crm.module.selectall": "S\u00e9lectionner tous les {0} dans cette vue",
"records": "enregistrements",
"crm.record.not.accessible": "Enregistrement(s) non accessible(s)",
"crm.view.calls.recording.err.msg": "L\u2019enregistrement vocal n\u2019a pas d\u2019URL audio lisible.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} s\u00e9lectionn\u00e9.</span>",
"totalrecords": "Total Enregistrements",
"crm.listview.options.text.clip": "Texte Clip",
"crm.listview.options.text.wrap": "Renvoyer \u00e0 la ligne automatiquement",
"crm.listview.options.reset.width": "R\u00e9initialisation de la taille des colonnes",
"crm.listview.options.manage": "G\u00e9rer les colonnes",
"crm.listview.customview.locked": "Les colonnes ne peuvent pas \u00eatre personnalis\u00e9es car cet affichage est verrouill\u00e9",
"crm.api.apidashboard.SortBy": "Trier par",
"crm.privacy.listview.consent.locked": "Ce dossier a \u00e9t\u00e9 verrouill\u00e9 contre le traitement",
"crm.label.reschedule.call": "Replanifier {0}",
"crm.button.edit": "Modifier",
"Delete": "Supprimer",
"crm.appointments.status.markcancel": "Annuler {0}",
"crm.appointments.status.markreschedule": "Replanifier {0}",
"crm.label.cancel.call": "Annuler {0}",
"crm.label.mark.completed": "Marquer comme termin\u00e9",
"crm.listview.customview.locked": "Les colonnes ne peuvent pas \u00eatre personnalis\u00e9es car cet affichage est verrouill\u00e9",
"pf.deleted.state": "\u00c9tat supprim\u00e9",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "Synchronis\u00e9 avec {0}",
"crm.schedules.prefix.in": "Dans",
"crm.record.record.locked.other": "L\u2019enregistrement est verrouill\u00e9 pour les autres utilisateurs du profil.",
"crm.record.lock.record.locked": "L\u2019enregistrement est verrouill\u00e9.",
"crm.approvalProcess.label.waitingForApproval": "En attente d\u2019approbation",
"crm.reviewprocess.record.review.pending": "En attente de r\u00e9vision",
"crm.approvalProcess.label.waitingForFindAndMerge": "La fusion des doublons est en cours.",
"crm.privacy.listview.consent.locked": "Ce dossier a \u00e9t\u00e9 verrouill\u00e9 contre le traitement",
"crm.zia.vision.processing": "Zia est en train de valider la/les image(s).",
"crm.zia.vision.rejected.msg": "L\u2019enregistrement est rejet\u00e9 en raison d\u2019un \u00e9chec de validation de l\u2019image.",
"crm.zia.vision.record.failure.msg": "L\u2019enregistrement est en attente d\u2019approbation en raison d\u2019un \u00e9chec de validation de l\u2019image.",
"crm.alert.max.cvcols.reached": "Vous pouvez s\u00e9lectionner au maximum {0}\u00a0colonnes.",
"crm.label.small.tags": "Balises",
"crm.label.edit.module": "Modifier {0}",
"crm.social.integ.no.result": "Aucun r\u00e9sultat",
"custommodule.crmfield.fl.name": "Nom {0}",
"Appointment": "Rendez-vous",
"crm.button.create.call": "Cr\u00e9er un appel",
"crm.module.create": "Cr\u00e9er un(e) {0}",
"crm.customview.activty.badge": "Badge d\u2019activit\u00e9",
"crm.button.callnow": "Appeler maintenant",
"crm.manualcalllist.complete.call": "Enregistrer un appel",
"crm.label.schedule.call": "Programmer un appel",
"crm.filter.label.replied":"a r\u00e9pondu",
"bytes": "octets",
"crm.field.label.user.lookup": "Utilisateur",
"crm.translation.refresh.cases": "L\u2019action que vous tentez d\u2019ex\u00e9cuter n\u2019a pas pu \u00eatre termin\u00e9e. Veuillez actualiser la page et r\u00e9essayez.",
"crm.project.sync.notes.info": "Seuls les commentaires de t\u00e2ches des Projets associ\u00e9s seront montr\u00e9s",
"crux.note.content.limit.warning.msg":"Autoris\u00e9 \u00e0 sauvegarder {0} caract\u00e8res uniquement dans le contenu des notes",
"crux.note.formatting.options":"Options de formatage",
"crm.label.required": "Obligatoire",
"crux.condition.not.belongs.to":"n\u2019appartient pas \u00e0 {0}",
"crm.project.sync.notes.info": "Seuls les commentaires de t\u00e2ches des Projets associ\u00e9s seront montr\u00e9s",
"crm.sf.permission.no.edit": "Vous n\u2019avez pas l\u2019autorisation de mettre \u00e0 jour des entr\u00e9es.",
"crm.sf.permission.no.create": "Vous n\u2019avez pas l\u2019autorisation d\u2019ajouter des entr\u00e9es.",
"crm.sf.permission.no.delete": "Vous n\u2019avez pas l\u2019autorisation de supprimer des entr\u00e9es.",
"crux.note.this.note":"cette note",
"KB": "Ko",
"MB": "Mo",
"current.logged.in.user.role": "Connect\u00e9 sous le r\u00f4le d\u2019utilisateur",
"Unit Price": "Prix unitaire",
"Qty in Stock": "Quantit\u00e9 en Stock",
"crm.label.Product Code": "Code produit",
"TotalAfterDiscount": "Total apr\u00e8s remise",
"crm.lookupfilter.entity.errormsg": "Le {0} s\u00e9lectionn\u00e9 ne correspond pas aux crit\u00e8res de recherche.",
"crm.iamexception.maxlen": "Vous ne pouvez pas entrer plus de {0} caract\u00e8res pour {1}",
"crm.record.locking.permission.denied": "Cette action ne peut \u00eatre effectu\u00e9e car l\u2019enregistrement est verrouill\u00e9.",
"List Price": "Tarif",
"crm.label.no.more.records": "Aucun autre enregistrement",
"crm.workflow.rule.created.dateAndTime":"{0} le {1} \u00e0 {2}",
"crux.new.note.msg":"\u00ab\u00a0Quel est l\u2019objet de cette note\u00a0?\u00a0\u00bb",
"crm.image.uploaded":"Image charg\u00e9e",
"crm.imageupload.drag.drop.here":"Glissez et d\u00e9posez les images ici",
"crm.fileupload.drag.drop.here":"Glissez et d\u00e9posez les fichiers ici",
"crm.fileupload.wait.msg":"Veuillez patienter jusqu\u2019\u00e0 ce que les fichiers s\u00e9lectionn\u00e9es soient t\u00e9l\u00e9charg\u00e9es",
"crm.fileupload.attach.fail":"\u00c9chec de la pi\u00e8ce jointe",
"crm.fileupload.support.format":"Format de fichier pris en charge (jpeg, jpg, png, pdf) pour l\u2019entra\u00eenement de Zia",
"custmr.prtl.user.role": "Utilisateur du portail",
"crm.subform.empty.alert.header":"Impossible d\u2019enregistrer une ligne vide",
"crm.crud.lookup.module.inaccessible": "Vous n\u2019avez pas l\u2019autorisation d\u2019acc\u00e9der \u00e0 ce module de recherche",
"crm.crud.lookup.inaccessible.record": "L\u2019enregistrement n\u2019existe pas ou n\u2019est pas accessible pour vous",
"crm.custombutton.valid.weburl.check": "Veuillez saisir une valeur.",
"crm.label.subform.goto.top": "Aller en haut de la liste",
"crm.label.subform.row.show.all": "Afficher tout ({0})",
"cpq.pr.nodiscount": "Aucune remise associ\u00e9e",
"crm.inventory.lineitem.no.pricebook.new": "Aucun {0} n\u2019est associ\u00e9.",
"crm.label.subform.allowedlimit": "Limite autoris\u00e9e de {0} lignes atteinte",
"crm.reports.integ.okay": "OK",
"crm.crud.subform.deleted.record": "Ligne(s) de sous-formulaire mise(s) \u00e0 jour a/ont d\u00e9j\u00e0 \u00e9t\u00e9 supprim\u00e9e(s). Veuillez actualiser la page et r\u00e9essayez.",
"crux.criteria.fieldlabel.valid.check":"Veuillez entrer un libell\u00e9 de champ valide",
"crm.subform.delete.confirm.header":"Supprimer une ligne de sous-formulaire",
"crm.subform.delete.confirm.msg": "Êtes-vous sûrs que vous voulez supprimer la ligne sélectionnée dans {0} ?",
"crm.subform.delete.tooltip.msg" :"La ligne ne peut pas être supprimée. Au moins une entrée d’une ligne est nécessaire pour le {0}.",
	"crm.condition.belongs.to":"appartient \u00e0 {0}",//no i18n
"crm.condition.not.belongs.to":"n\u2019appartient pas \u00e0 {0}",//no i18n
"crm.orchestration.error.selectvalue": "S\u00e9lectionnez une valeur",
	"crm.attach.upload.image":"Joindre des images",//no i18n
"crm.general.select.photo":"S\u00e9lectionner une image",//no i18n
"crm.imageupload.wait.msg":"Veuillez patienter jusqu\u2019\u00e0 ce que les images s\u00e9lectionn\u00e9es soient t\u00e9l\u00e9charg\u00e9es.",//no i18n
"crm.image.filepicker.desc.draganddrop":"Glisser-d\u00e9poser des images.",//no i18n
"crm.image.filepicker.desc.browse":"Cliquez pour parcourir les images....",//no i18n
"crm.attach.upload.userinfo":"La taille totale est limit\u00e9e \u00e0 <span class",//no i18n
"crm.image.supported.formats":"Formats pris en charge\u00a0: JPEG, PNG, GIF et BMP.",//no i18n
"crm.imageupload.allowed.field.length":"Vous pouvez t\u00e9l\u00e9charger un maximum de {0} image(s) uniquement.",//no i18n
"crm.general.crop.and.set":"Recadrer et d\u00e9finir",//no i18n
"crm.attach.option.label":"Joindre",//no i18n
"crm.image.crop.and.rotate":"Rogner et faire pivoter",//no i18n
"crm.image.resolution":"R\u00e9solution",//no i18n
"crm.attachment.size":"Taille",//no i18n
"crm.label.close":"Fermer",//no i18n
"crm.label.previous":"Pr\u00e9c\u00e9dent",//no i18n
"crm.label.next":"Suivant",//no i18n
"crm.subform.record.create.maxrow.limit": "Maximum de {0} enregistrements autoris\u00e9 dans {1}",
"crm.label.subform.addrows": "Ajouter une ligne",
"crm.tax.association.check": "Aucune taxe associ\u00e9e \u00e0 ce produit.",
	"crm.gallery.inventory.template.discount": "Remise",
	"crm.inventory.discount.scheme.range": "Plage",
	"Description":"Description",
	"crm.inventory.lineitem.no.pricebook": "Aucun livre des prix n\u2019est associ\u00e9.",
	"crm.recordImage.previewimage":"Aper\u00e7u de l\u2019image",//no i18n
"crm.FileuploadField.addNewImage":"Charger l\u2019image",//no i18n
"crm.fileuploader.removefile":"Supprimer",//no i18n
	"voc.gc.configure":"Configurer",//no i18n
"Edit":"Modifier",//no i18n
	"crm.view.attachment.download":"T\u00e9l\u00e9charger",//no i18n
"crm.label.view":"Consulter",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "Veuillez fournir la valeur correspondante pour\u00a0{0}",
	"crm.label.field":"Champ",//no i18n
"crm.label.value":"Valeur",//no i18n
	"crm.button.clone":"Cloner",//no i18n
	"sentiment.criteria.wrongcriteria":"La valeur du crit\u00e8re ne peut pas \u00eatre sup\u00e9rieure \u00e0 {0}",//no i18n
"crm.mb.field.common.splc":"Les caract\u00e8res sp\u00e9ciaux ne sont pas autoris\u00e9s. Saisissez une valeur valide.",//no i18n
	"crm.label.field.plural":"champs",//no i18n
	"crm.label.in.minutes":"{0} (en minutes)",//no i18n
	"crm.security.roles.list":"Liste des R\u00f4les",//no i18n
"crm.security.roles.lookup.info":"S\u00e9lectionnez un r\u00f4le dans la liste.",//no i18n
"crm.territory.addterritory":"Ajouter un secteur",//no i18n
"crm.title.edit.territory":"Modifier un secteur",//no i18n
"crm.territory.title.assign.territories":"Attribuer des secteurs",//no i18n
	"crm.label.context.help":"Aide",//no i18n
	"crm.label.from":"Du",//no i18n
"crm.label.to":"Au",//no i18n
	"workflow.option.webhookFailure.fromDate":"Date de d\u00e9but",//no i18n
"workflow.option.webhookFailure.toDate":"Date de fin",//no i18n
"crm.custom.field.less.than.equalto":"{0} doit \u00eatre inf\u00e9rieur ou \u00e9gal \u00e0 {1}.",//no i18n
	"crm.template.listview.search.no.results":"Aucun r\u00e9sultat",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"La date de d\u00e9but ne peut \u00eatre post\u00e9rieure \u00e0 la date de fin.",//no i18n
	"crm.label.tag.new":"Nouvelle balise",//No I18n
	"crm.label.enter.tag":"Saisir les balises",//No I18n
	"crux.comboBox.max.limit":"Vous ne pouvez pas s\u00e9lectionner plus de {0} {1}.",//NO I18n
	"Administrator":"Administrateur",//No I18n
	"Standard":"Standard",//No I18n
	"crm.button.add":"Ajouter",//NO I18n
	"crm.label.users":"Utilisateurs", //NO I18n
  "crm.workflow.alert.roles":"R\u00f4les", //NO I18n
  "crm.security.groups":"Groupes", //NO I18n
	"crm.label.available" : "Disponible", //NO I18n
	"crm.label.assign.manually" : "Attribuer", //NO I18n
	"crm.globalsearch.option.all": "Tous", //NO I18n
	"webform.status.Active":"Actif", //NO I18n
	"Inactive":"Inactif", //NO I18n
  "Confirmed":"Confirm\u00e9", //NO I18n
  "crm.user.component.unconfirmed":"Non confirm\u00e9",//no i18n
  "DeletedUser":"Supprim\u00e9", //NO I18n
  "crm.feed.group.admin":"Administrateur", //NO I18n
  "crm.ln.lable.current":"Actuelle", //NO I18n
	"crm.label.selected": "Selectionn\u00e9",//NO I18n
	"crm.auditlog.user": "Utilisateur", //NO I18n
	"cob.role": "R\u00f4le", //NO I18n
	"zoho.email": "E-mail", //NO I18n
	"Profile": "Profil", //NO I18n
	"crm.security.group.users.empty": "Aucun utilisateur n\u2019a \u00e9t\u00e9 trouv\u00e9.", //NO I18n
	"crm.label.picklist.none": "Aucune",//NO I18n
	"crm.usrpop.non.selected" : "Utilisateurs s\u00e9lectionn\u00e9s",//NO I18n
	"crm.label.notSelected" : "Non s\u00e9lectionn\u00e9",//NO I18n
	"AM" : "matin",//NO I18n
	"Call" : "Appel",//NO I18n
	"crm.phoneNo.Link.Title" : "Appeler avec Skype",//NO I18n
	"crm.no.data.found" : "Aucune donn\u00e9e trouv\u00e9e.",//NO I18n
	"crm.zti.label.user": "Nom d\u2019utilisateur", //NO I18n
	"crm.label.no.options.found" : "Aucune option trouv\u00e9e.",//No I18n
	"crm.globalsearch.search.title" : "Rechercher",//No I18n
	"None" : "Aucune",//No I18n
	"crm.label.criteria.pattern" : "Mod\u00e8le de combinaison de crit\u00e8res",//No I18n
	"crm.label.edit.criteria.pattern" : "Modifier le mod\u00e8le",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Les parenth\u00e8ses du mod\u00e8le ne correspondent pas.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Parenth\u00e8ses non valides entourant Op\u00e9rateur(s) de condition.",//No I18n
	"crm.criteria.number.notmatch.check" : "Veuillez v\u00e9rifier le mod\u00e8le sur {0}.",//No I18n
	"criteria.error.alert.other.params" : "Contenu non valide dans ce mod\u00e8le.", //No I18n
	"crm.label.search.for.users": "Rechercher des utilisateurs", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Le mod\u00e8le de crit\u00e8res ne correspond pas aux conditions que vous avez choisies.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Le mod\u00e8le de crit\u00e8res ne correspond pas aux conditions que vous avez choisies.", //No I18n
	"and" : "et", //No I18n
	"or" : "ou", //No I18n
	"crm.label.or" : "OU", //No I18n
	"crm.label.and" : "ET", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Veuillez entrer une \u00e9tiquette de champ valide dans la ligne {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Veuillez sp\u00e9cifier une condition valide pour {0}.", //No I18n
	"crm.field.valid.check" : "Entrez un {0} valide.", //No I18n
	"crm.custom.field.less.than.to" : "La plage de <i>d\u00e9but</i> ne peut \u00eatre sup\u00e9rieure \u00e0 la plage de <i>fin</i>.", //No I18n
	"crm.alert.label.savepattern" : "Enregistrez le mod\u00e8le avant de modifier les crit\u00e8res.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Vous ne pouvez pas ajouter d\u2019autres crit\u00e8res.",//No I18n
	"is" : "est",//No I18n
	"isn\'t" : "n\u2019est pas",//No I18n
	"contains" : "contient",//No I18n
	"doesn\'t contain" : "ne contient pas",//No I18n
	"starts with" : "commence par",//No I18n
	"ends with" : "finit par",//No I18n
	"is empty" : "est vide",//No I18n
	"is not empty" : "n\u2019est pas vide",//No I18n
	"is before" : "est avant",//No I18n
	"is after" : "est apr\u00e8s",//No I18n
	"between" : "entre",//No I18n
	"not between" : "n\u2019est pas entre",//No I18n
	"Today" : "Aujourd\u2019hui",//No I18n
	"Tommorow" : "Demain",//No I18n
	"Tommorow Onwards" : "D\u00e9marrage demain",//No I18n
	"Yesterday" : "Hier",//No I18n
	"Till Yesterday" : "Jusqu\u2019\u00e0 Hier",//No I18n
	"Last Month" : "Le mois dernier",//No I18n
	"Current Month" : "Ce mois", //No I18n
	"Next Month" : "Le mois prochain", //No I18n
	"Last Week" : "La semaine derni\u00e8re", //No I18n
	"Current Week" : "Cette semaine", //No I18n
	"Next Week" : "La semaine prochaine", //No I18n
	"Age in Days" : "Anciennet\u00e9 en jours", //No I18n
	"Due in Days" : "\u00c9ch\u00e9ance en Jours", //No I18n
	"Scheduled" : "Planifi\u00e9", //No I18n
	"Attended Dialled" : "Num\u00e9rotation manuelle", //No I18n
	"Unattended Dialled" : "Num\u00e9rotation non surveill\u00e9e", //No I18n
	"Overdue" : "En retard", //No I18n
	"Cancelled" : "Annul\u00e9", //No I18n
	"Received" : "Re\u00e7u", //No I18n
	"Missed" : "Manqu\u00e9", //No I18n
	"crm.alert.character.not.allowed" : "{0} n\u2019est pas autoris\u00e9", //No I18n
	"crm.condition.in.last" : "dans le dernier", //No I18n
	"crm.zinvoice.dueIn" : "d\u00fb dans", //No I18n
	"on" : "en",//No I18n
	"before" : "avant",//No I18n
	"crm.label.general.small.after" : "apr\u00e8s",//No I18n
	"crm.thisweek" : "Cette semaine",//No I18n
	"crm.label.this.month" : "Ce mois",//No I18n
	"crm.thisyear" : "Cette ann\u00e9e",//No I18n
	"crm.source.user.and.system" : "Utilisateur et Syst\u00e8me",//No I18n
	"crm.source.user.or.system" : "Utilisateur ou Syst\u00e8me",//No I18n
	"crm.label.system2" : "Syst\u00e8me",//No I18n
	"crm.source.user.only" : "Uniquement par Utilisateur",//No I18n
	"crm.source.system.only" : "Uniquement par Syst\u00e8me",//No I18n
	"crm.condition.till.today" : "Jusqu\u2019\u00e0 aujourd\u2019hui",//No I18n
	"game.month.left" : "1 mois",//No I18n
	"game.months.left" : "{0} mois",//No I18n
	"crm.condition.last.30.days" : "au cours des 30\u00a0derniers jours",//No I18n
	"crm.condition.last.60.days" : "au cours des 60\u00a0derniers jours",//No I18n
	"crm.condition.last.90.days" : "au cours des 90\u00a0derniers jours",//No I18n
	"crm.label.filter.typehere" : "Tapez ici", //No I18N
	"crm.filter.is.not" : "n\u2019est pas", //No I18n
	"crm.condition.until.now" : "Jusqu\u2019\u00e0 maintenant",//No I18n
	"crm.filter.email.isblocked" : "est bloqu\u00e9",//No I18n
	"crm.filter.email.isnotblocked" : "n\u2019est pas bloqu\u00e9",//No I18n
	"crm.label.no.results.match" : "Aucun r\u00e9sultat correspondant",//No I18n
	"crm.label.select.user" : "Cliquez pour s\u00e9lectionner des utilisateurs.", //No I18n
	"current.logged.in.user": "Utilisateur connect\u00e9", //NO I18n
	"current.logged.in.user.definition": "L\u2019utilisateur qui d\u00e9marre l\u2019Action d\u2019enregistrement.", //NO i18n
	"crm.security.group": "Groupe", //NO I18n
	"crm.security.role": "R\u00f4le", //NO I18n
	"Date" : "Date",//No I18n
	"crm.field.valid.decimal.check2" : "Le nombre de d\u00e9cimales pour le champ <i>{0}</i> doit \u00eatre inf\u00e9rieur ou \u00e9gal \u00e0 {1}.",//No I18n
	"crm.field.empty.check" : "{0} ne peut pas \u00eatre vide.",//No I18n
	"crm.label.add.note": "Ajouter une remarque", //NO I18n
	"crm.label.simply.by": "par", //NO I18n
	"crm.general.addnote": "Ajouter une note", //NO I18n
	"crm.general.addtitle": "Ajouter un titre", //NO I18n
	"crm.label.attach.file": "Joindre le fichier", //NO I18N
	"crm.button.cancel": "Annuler", //NO I18N
	"crm.button.save": "Enregistrer", //NO I18N
	"crm.button.mass.delete": "Supprimer", //NO I18N
	"crm.warning.delete.record": "Voulez-vous r\u00e9ellement d\u00e9placer \u00ab\u00a0{0}\u00a0\u00bb dans la corbeille\u00a0?", //NO I18N
	"crm.label.yes": "Oui", //NO I18N
	"crm.note.view.previous": "Afficher les notes pr\u00e9c\u00e9dentes", //NO I18N
  "of": "de", //NO I18N
	"crm.label.notes": "Remarques", //NO I18N
	"crm.note.recent.first": "Premi\u00e8re r\u00e9cente", //NO I18N
	"crm.note.recent.last": "Derni\u00e8re r\u00e9cente", //NO I18N
	"crm.territory.label.only": "{0} uniquement", //no i18n
	"crm.select" : "S\u00e9lectionner",//No I18n
	"crm.button.apply.filter" : "Appliquer le filtre",//No I18n
	"crm.alert.maximum.text.values.contains" : "Vous ne pouvez pas entrer plus de {0} valeurs dans ce champ.",//No I18n
	"PM" : "apr\u00e8s-midi",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "Les valeurs dupliqu\u00e9es ne sont pas autoris\u00e9es.",//no i18n
	"crm.duplicate.value.available": "Un {0} existant avec le m\u00eame {1} a \u00e9t\u00e9 trouv\u00e9.",//no i18n
	"crm.duplicate.value.available.multiple": "Plusieurs {0} avec le m\u00eame {1} ont \u00e9t\u00e9 trouv\u00e9s.",//no i18n
	"crm.custombutton.nobuttons.found": "Aucun bouton trouv\u00e9",//no i18n
	"crm.custombutton.create.newbutton": "Cr\u00e9er un bouton",//no i18n
	"crm.custombutton.manage.button": "Boutons G\u00e9rer",//no i18n
	"crm.custombutton.name": "Nom",//no i18n
	"crm.customize.custombutton.function.desc": "Description",//no i18n
	"crm.custombutton.action": "Action du bouton",//no i18n
	"crm.custombutton.empty.field.values": "La valeur vide trouv\u00e9e pour le ou les champs suivants\u00a0:",//no i18n
	"crm.custombutton.proceed.action": "\u00cates-vous toujours s\u00fbr de vouloir continuer\u00a0?",//no i18n
	"zb.common.Cancel": "Annuler",//no i18n
	"crm.yes.proceed": "Oui, continuer.",//no i18n
	"crm.label.module.merge": "Fusionner les {0}",//no i18n
	"crm.view.record": "Afficher {0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "Choisir un fichier",//no i18n
	"crm.label.subform.addrows": "Ajouter une ligne",//no i18n
	"crm.button.save&new": "Enregistrer et Nouveau",//no i18n
	//end-cx create form keys
	"Jan" : "Jan",//No I18n
	"Feb" : "F\u00e9v",//No I18n
	"Mar" : "Mar",//No I18n
	"Apr" : "Avr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Ao\u00fb",//No I18n
	"Sep" : "Sep",//No I18n
	"Oct" : "Oct",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "D\u00e9c",//No I18n
	"crm.mb.newversion.msg4" : "OK, j\u2019ai compris\u00a0!", //no i18n
	"crm.label.More" :"Plus", //no i18n

	"crm.label.unmapped.stages" : "Sans compte", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Afficher plus",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Afficher moins",//No I18n

	 	 //filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"SIMILITUDE",//no i18n
	"crm.lead.prediction.tooltip.score":"SCORE",//no i18n
	"Planned":"Planifi\u00e9",//no i18n
	"Invited":"Invit\u00e9",//no i18n
	"Sent":"Envoy\u00e9e",//no i18n
	"Received":"Re\u00e7u",//no i18n
	"Opened":"Ouvert",//no i18n
	"Responded":"R\u00e9ponse obtenue",//no i18n
	"Bounced":"Retourn\u00e9",//no i18n
	"Opted\ Out":"A choisi de ne pas participer",//no i18n
	"crm.filter.label.with.open":"Avec {0} ouvert",//no i18n
	"crm.filter.label.without.open":"Sans {0} ouvert",//no i18n
	"crm.filter.label.without.any":"Sans aucun {0}",//no i18n
	"crm.filter.label.with.module":"Avec {0}",//no i18n
	"crm.filter.label.activity.due":"{0} d\u00fb",//no i18n
	"crm.filter.label.activity.done":"{0} effectu\u00e9",//no i18n
	"Notes":"Remarques",//no i18n
	"crm.filter.label.notes.added":"Remarques ajout\u00e9es",//no i18n
	"crm.label.filter.email.status":"Dernier \u00e9tat des e-mails",//no i18n
	"crm.label.filter.email.clicked":"cliqu\u00e9",//no i18n
	"crm.label.filter.email.responded":"r\u00e9pondu", //no i18n
	"crm.label.filter.email.info":"Filtrez les enregistrements en fonction du dernier statut de vos e-mails re\u00e7us/envoy\u00e9s",//no i18n
	"crm.filter.label.sent":"envoy\u00e9",//no i18n
	"crm.filter.label.not.sent":"non envoy\u00e9",//no i18n
	"crm.filter.label.opened":"ouvert",//no i18n
	"crm.filter.label.not.opened":"non ouvert",//no i18n
	"crm.filter.label.received":"re\u00e7u",//no i18n
	"crm.filter.label.not.received":"non re\u00e7u",//no i18n
	"crm.filter.label.bounced":"retourn\u00e9",//no i18n
	"crm.filter.label.opened.not.replied":"ouvert et non r\u00e9pondu", //no i18n
	"crm.filter.label.any":"L\u2019une ou l\u2019autre option ci-dessus",//no i18n
	"crm.zia.config.potential.amount":"Montant de {0}",//no i18n
	"Quote\ Stage":"Statut du {0}",//no i18n
	"crm.module.owner":"Gestionnaire de {0}",//no i18n
	"Potential\ Closing\ Date":"Date de cl\u00f4ture du {0}",//no i18n
	"crm.lead.prediction.likely.convert":"Conversion probable",//no i18n
	"crm.lead.prediction.convert.high":"Haut",//no i18n
	"crm.lead.prediction.convert.medium":"Moyen",//no i18n
	"crm.lead.prediction.convert.low":"Bas",//no i18n
	"crm.predictions.feature.label":"Pr\u00e9diction",//no i18n
	"crm.intelligence.prediction.likelywin":"Probablement gagnant",//no i18n
	"crm.intelligence.prediction.likelylose":"Probablement perdant",//no i18n
	"crm.intelligence.prediction.halfchance":"1\u00a0chance sur 2",//no i18n
	"crm.intelligence.prediction.score":"Score de la pr\u00e9diction",//no i18n
	"crm.lead.prediction.recent.score":"Score de la pr\u00e9diction r\u00e9cente",//no i18n
	"crm.intelligence.prediction.lastconv":"3\u00a0derni\u00e8res conversations",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Enregistrements \u00e0 cibler",//no i18n
	"crm.intelligence.prediction.slowmoving":"D\u00e9placement lent",//no i18n
	"crm.intelligence.prediction.trend.down":"Tendance r\u00e9cemment en baisse",//no i18n
	"crm.label.touched.records":"Enregistrements touch\u00e9s",//no i18n
	"crm.label.untouched.records":"Enregistrements non touch\u00e9s",//no i18n
	"crm.label.record.action":"Action d\u2019enregistrement",//no i18n
	"workflow.rule.view.label.Modified":"Modifi\u00e9",//no i18n
	"crm.label.not.modified":"Non modifi\u00e9",//no i18n
	"crm.label.related.records.action":"Action d\u2019enregistrements connexes",//no i18n
	"Done":"Termin\u00e9",//no i18n
	"crm.label.not.done":"Non effectu\u00e9",//no i18n
	"sentiment.model":"Analyse de sentiments par e-mail",//no i18n
	"sentiment.criteria.count":"Compte",//no i18n
	"sentiment.criteria.percentage":"Pourcentage",//no i18n
	"sentiment.criteria.lastmail":"Pour le dernier e-mail",//no i18n
	"Chats":"Chats",//no i18n
	"Attended":"A particip\u00e9",//no i18n
	"crm.lead.prediction.popup.text":"Lorsque la probabilit\u00e9 de conversion est {0}, le score de pr\u00e9diction doit \u00eatre entre {1}.",//no i18n
	"crm.lead.prediction.popup.final":"Veuillez modifier le filtre en cons\u00e9quence puis r\u00e9essayer.",//no i18n
	"crm.custom.field.less.than.to1":"La plage De ne peut pas \u00eatre sup\u00e9rieure \u00e0 la plage \u00c0.",//no i18n
	"Last\ Activity\ Date":"Date de la derni\u00e8re activit\u00e9",//no i18n
	"crm.label.vendor.name":"Nom de {0}",//no i18n
	"hours":"heures",//no i18n
	"days":"jour",//no i18n
	"weeks":"semaine",//no i18n
	"months":"mois",//no i18n
	"years":"ans",//no i18n
	"crm.label.general.small.after":"apr\u00e8s",//no i18n
	"Last\ Week":"La semaine derni\u00e8re",//no i18n
	"Last\ Month":"Le mois dernier",//no i18n
	"crm.module.name":"Nom de {0}",//no i18n
	"Campaign":"Campagne",//no i18n
	"Tasks":"T\u00e2ches",//no i18n
	"Calls":"Appels",//no i18n
	"Events":"\u00c9v\u00e9nements",//no i18n
	"sentiment.criteria.wrongcriteria":"La valeur du crit\u00e8re ne peut pas \u00eatre sup\u00e9rieure \u00e0 {0}",//no i18n
	"crm.chosen.minimum.input.text":"Veuillez entrer au moins {0} caract\u00e8res",//no i18n
	"crm.intelligence.prediction.trendup":"Tendance \u00e0 la hausse",//no i18n
	"crm.intelligence.prediction.trenddown":"Tendance \u00e0 la baisse",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Termin\u00e9" ,//no i18n
	"crm.label.success":"Succ\u00e8s" ,//no i18n
	"crm.label.Failure":"\u00c9chec" ,//no i18n
	"Both":"Les deux" ,//no i18n
	"crm.condition.cannot.empty":"La condition doit \u00eatre renseign\u00e9e.",//no i18n
	"crm.condition.last.30.days":"au cours des 30\u00a0derniers jours",//no i18n
	"crm.condition.last.60.days":"au cours des 60\u00a0derniers jours",//no i18n
	"crm.condition.last.90.days":"au cours des 90\u00a0derniers jours",//no i18n
	"crm.sentiment.Positive":"Positif",//no i18n
	"crm.sentiment.Negative":"N\u00e9gatif",//no i18n
	"sentiment.positiveandnegative":"Positif et n\u00e9gatif",//no i18n
	"sentiment.positiveornegative":"Positif ou n\u00e9gatif",//no i18n
	"sentiment.positiveonly":"Positif seulement",//no i18n
	"sentiment.negativeonly":"N\u00e9gatif seulement",//no i18n
	"crm.sentiment.Neutral":"Neutre",//no i18n
	"crm.filters.select.campaign.type":"S\u00e9lectionner le type de {0}",//no i18n
	"crm.filters.select.campaign.status":"S\u00e9lectionner le statut de {0}",//no i18n
	"campaign.Member" : "Membre",//no i18n
	"Service":"Service",//no i18n
	"Activities":"Activit\u00e9s",//no i18n
	"crm.livedesk.pot.nextdays":"{0} prochains jours",//no i18n
	"Today\ +\ Overdue":"Aujourd\u2019hui + en retard",//no i18n
	"crm.source.user.and.system":"Utilisateur et Syst\u00e8me",//no i18n
	"crm.source.user.or.system":"Utilisateur ou Syst\u00e8me",//no i18n
	"User":"Utilisateur",//no i18n
	"crm.source.user.only":"Uniquement par Utilisateur",//no i18n
	"crm.source.system.only":"Uniquement par Syst\u00e8me",//no i18n
	"Scheduled":"Planifi\u00e9",//no i18n
	"Attended\ Dialled":"Num\u00e9rotation manuelle",//no i18n
	"Unattended\ Dialled":"Num\u00e9rotation non surveill\u00e9e",//no i18n
	"Cancelled":"Annul\u00e9",//no i18n
	"crm.filter.email.isblocked":"est bloqu\u00e9",//no i18n
	"crm.filter.email.isnotblocked":"n\u2019est pas bloqu\u00e9",//no i18n
	"condition.till.now":"Jusqu\u2019\u00e0 maintenant",//no i18n
	"crm.recurring.no.months":"{0} mois",//no i18n
	"crm.lead.prediction.tooltip":"Conversion probable - Plage de scores",//no i18n
	"crm.website.activity":"Activit\u00e9 du site Web",//no i18n
	"crm.label.By":"Par",//no i18n
	"crm.chosen.searching.text":"Recherche...",//no i18n
	"crm.label.memberstatus.is":"et le statut du membre est",//no i18n
	"crm.events.duration":"Dur\u00e9e",//no i18n
	"crm.title.clear.name":"Effacer",//no i18n
	"crm.label.status.is":"et l\u2019\u00e9tat est",//no i18n
	"zia.last3.help":"La conversation comprend les appels, les t\u00e2ches, les {0}, les e-mails re\u00e7us, les notes, les visites, les commentaires sur les r\u00e9seaux sociaux, les demandes de support depuis le bureau.",//no i18n
	"crm.label.tag.related.to":"associ\u00e9 \u00e0",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Un nouveau {0} va \u00eatre cr\u00e9\u00e9.",//No I18n
	"crm.krp.no.records.found" : "Aucun {0} trouv\u00e9",//No I18n
	"crm.module.new" : "Cr\u00e9er {0}",//No I18n
	"crm.label.view" : "Consulter",//No I18n
	"crm.nsocial.customers" : "Clients",//No I18n
	"crm.nsocial.open.potential" : "{0} ouvert",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Autres",//No i18n
	"crm.field.length.check" : "La valeur {0} d\u00e9passe la longueur maximale.",//No I18n
	"crm.lower.now":"maintenant",//no i18n
	"crm.time.min.ago":"Il y a {0} min.",//no i18n
	"crm.time.mins.ago":"Il y a {0} min.",//no i18n
	"crm.time.hr.ago":"Il y a {0} h.",//no i18n
	"crm.time.hrs.ago":"Il y a {0} h.", //no i18n
	"AllUsers": "Tous les utilisateurs", //no i18n
	"crm.label.search":"Rechercher",//no i18n
	"crm.api.filterby":"Filtrer par",//no i18n
	"crm.customview.nofields.found":"--Aucun champ correspondant--",//no i18n
	"crm.setup.system.ziarecommendation":"Recommandation",//no i18n
	"crm.filter.label.all.products":"Tous les {0}",//no i18n
	"crm.filter.label.select.products":"{0} s\u00e9lectionn\u00e9",//no i18n
	"crm.reviewprocess.smart.filter":"Statut de l\u2019enregistrement du processus de r\u00e9vision",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Veuillez choisir une valeur.",//no i18n
	"crm.segmentation.segment.score":"Score du segment",//no i18n
	"crm.filter.label.in":"fond\u00e9 sur",//no i18n
	"crm.filter.label.and.purchase.in":"et susceptibles d\u2019\u00eatre achet\u00e9s dans",//no i18n
	"crm.filter.label.last.purchased":"et r\u00e9cemment acquis",//no i18n
	"crm.filter.label.a.day":"par jour",//no i18n
	"crm.filter.label.a.week":"par semaine",//no i18n
	"crm.filter.label.a.month":"un mois",//no i18n
	"crm.cal.custom":"Personnalis\u00e9",//no i18n
	"crm.mb.field.common.empt":"La valeur doit \u00eatre renseign\u00e9e.",//no i18n
	"crm.chosen.error.loading.text":"Oups, nous n\u2019avons pas \u00e9t\u00e9 en mesure de charger vos r\u00e9sultats",//no i18n
	"crm.filter.label.firstbuy":"Pour la premi\u00e8re fois",//no i18n
	"crm.filter.label.cwbab":"D\u00e9pendant",//no i18n
	"crm.filter.label.fbt":"Offre group\u00e9e",//no i18n
	"crm.filter.label.rebuy":"R\u00e9p\u00e9ter",//no i18n
	"crm.filter.label.nextbuy":"S\u00e9quence",//no i18n
	"crm.mxnlookup.select" : "Attribuer {0}",//No I18n
	"crm.lookup.chooserecord":"Choisir {0}",//no i18n
	"crm.record.selected":"S\u00e9lectionn\u00e9 {0}",//no i18n
	"crm.module.empty.message" : "Impossible de trouver des {0}",//No I18n
	"crm.mxnlookup.selected" : "{0} affect\u00e9",//No I18n
	"crm.label.creator.noPermission" : "Autorisation refus\u00e9e", //no i18n
	"crm.security.error" : "Vous ne disposez pas d\u2019autorisations suffisantes pour effectuer cette op\u00e9ration. Veuillez contacter votre administrateur.", //no i18n
	"crm.segmentation.recency" : "R\u00e9cence",  //no i18n
	"crm.segmentation.frequency" : "Fr\u00e9quence",  //no i18n
	"crm.segmentation.monetary" : "Mon\u00e9taire",  //no i18n
	"crm.smartfilter.related.module.msg" : "Vous ne pouvez pas s\u00e9lectionner plus de trois modules associ\u00e9s.",  //no i18n
	"crm.smartfilter.related.module.msg1" : "(Par ex.\u00a0: e-mail, activit\u00e9s, notes)",  //no i18n
	"crm.smartfilter.related.module.msg2" : "La dur\u00e9e ne peut pas \u00eatre vide",  //no i18n
	"crm.label.timeZone": "Fuseau Horaire", //NO I18n
	"crm.label.insufficient.privileges": "Privil\u00e8ges insuffisants pour ex\u00e9cuter cette op\u00e9ration. Contactez votre administrateur.", //NO I18n
	"crm.filter.header.secton.system": "Filtres d\u00e9finis par le syst\u00e8me", //NO I18N
	"crm.filter.header.secton.fields": "Filtrer par champs", //NO I18N
	"crm.createfield.calcinfo.new" : "Le champ agit comme calculateur de n\u2019importe quelle expression entr\u00e9e.</br> <b>P. ex. 20+20</b> donnera automatiquement <b>40</b>",//No i18n
	"crm.lable.read.only" : "Champ en lecture seule",//No i18n
	"crm.column.sort.asc" : "Asc",//No I18n
	"crm.column.sort.desc" : "Desc",//No i18n
	"crm.column.unsort" : "Pas de tri",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Partager avec le client", //NO I18N
	"crm.label.edited":"Modifi\u00e9",//no i18n
	"crm.label.edited.on":"Modifi\u00e9 le",//no i18n
	"crm.message.limit.exceed": "Un maximum de {0} caract\u00e8res est autoris\u00e9 pour {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Partag\u00e9 avec le client", //NO I18N
	"crm.button.ok" : "OK", //NO I18N
	"crm.role.already.selected" : "Ce r\u00f4le est d\u00e9j\u00e0 s\u00e9lectionn\u00e9", //no i18n
	"crm.user.deleted": "L'UTILISATEUR A \u00c9T\u00c9 SUPPRIM\u00c9",  //NO I18N
	"crm.account.closed": "CE COMPTE A \u00c9T\u00c9 FERM\u00c9",  //NO I18N
	"crm.start.chat": "D\u00e9marrer la conversation",  //NO I18N
	"crm.start.call": "D\u00e9marrer un appel",  //NO I18N
	"crm.recipient.invalid.email" : "E-mails invalides trouv\u00e9s.", //NO I18N
    "crm.recipient.add.recipient" : "Ajouter un destinataire suppl\u00e9mentaire", //NO I18N
	"crm.start.video.call": "D\u00e9marrer un appel vid\u00e9o",  //NO I18N //ignorei18n_start

	"Score":"Score",
	"Positive Score":"Score positif",
	"Negative Score":"Score n\u00e9gatif",
	"Touch Point Score":"Score des points de contact",
	"Positive Touch Point Score":"Score des points de contact positif",
	"Negative Touch Point Score":"Score des points de contact n\u00e9gatif",
	"crm.label.scoring.rules":"R\u00e8gles de notation",
	"crm.label.type.minutes": "apez ici en quelques minutes", //NO I18N

	"is\ OPEN":"est OUVERT",//no i18n
	"is\ WON":"est GAGN\u00c9",//no i18n
	"is\ LOST":"est PERDU",//no i18n
	"crm.potential.all.open":"Toutes les \u00e9tapes ouvertes",//no i18n
	"crm.potential.all.won":"Toutes les \u00e9tapes ferm\u00e9es gagn\u00e9es",//no i18n
	"crm.potential.all.lost":"Toutes les \u00e9tapes ferm\u00e9es perdues",//no i18n

	"crm.campaign.member.status" : "Statut du membre",//no i18n
	"crm.dashboard.select.type" : "S\u00e9lectionner {0}",//no i18n
	"crm.campaign.service.status" :"\u00c9tat du Service",//no i18n

	"crm.label.addColumn":"Ajouter une colonne",//no i18n
	"crm.button.clear.filter":"Fermer le filtre",//no i18n
	"crm.button.show.filter":"Afficher filtre",//no i18n
	"crm.las.error.user.maxlimit":"Vous pouvez s\u00e9lectionner au maximum 20 utilisateurs.",//no i18n
	"crm.las.error.picklist.maxlimit":"Vous pouvez s\u00e9lectionner au maximum 20 options.",//no i18n

	"crm.fileuploader.message.responseerror": "\u00c9chec du chargement", //NO I18N
	"crm.storage.create.error":"Aucun nouvel enregistrement ne peut \u00eatre cr\u00e9\u00e9 car vous avez atteint votre limite maximale de stockage de donn\u00e9es.",//no i18n
	"crm.storage.create.error.client":"Aucun nouvel enregistrement ne peut \u00eatre cr\u00e9\u00e9 parce que votre administration a atteint sa limite de stockage maximale. Contactez {0} pour r\u00e9soudre ce probl\u00e8me.",//no i18n
	"crm.storage.avail.info":"({0} restant de {1})",//no i18n
	"crm.storage.error.key.manage":"G\u00e9rer le stockage de vos donn\u00e9es",//no i18n
	"Records":"Enregistrements",//no i18n
	"crm.workflow.alert.additional.recipients" : "Destinataires suppl\u00e9mentaires", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Utilisez des virgules pour s\u00e9parer les diff\u00e9rentes adresses e-mail.", //NO I18N
	"crm.related.contact.account" : "{0} associ\u00e9 \u00e0 {1}",//No I18n
	"crm.allcontact.show" : "Tous {0}",//No I18n
	"crm.button.mass.show" : "Afficher",//No I18n
	"crm.msg.custom.view.not.replied" : "Messages non r\u00e9pondus", //NO I18N
	"crm.msg.custom.view.replied" : "Messages r\u00e9pondus",//NO I18N
	"crm.workflow.select.recipients":"Destinataires", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Veuillez s\u00e9lectionner au moins un profil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "D\u00e9sol\u00e9s, mais vous ne pouvez pas supprimer un profil par d\u00e9faut.",//NO I18N
	"crm.inv.label.add.emails" : "Ajouter des e-mails", //NO I18N
	"crm.prediction.analytics.filter.year":"L\u2019ann\u00e9e derni\u00e8re",//no i18n
	"Previous\ FY":"L\u2019ann\u00e9e pr\u00e9c\u00e9dente",//no i18n
	"Current\ FY":"Cette ann\u00e9e",//no i18n
	"Next\ FY":"L\u2019ann\u00e9e prochaine",//no i18n
	"Current\ FQ":"Ce trimestre",//no i18n
	"Next\ FQ":"Le trimestre prochain",//no i18n
	"Previous\ FQ":"Pr\u00e9vision pr\u00e9c\u00e9dente",//no i18n
	"crm.picklist.sample.text":"Texte d\u2019exemple",//no i18n
	"crm.more.colors":"Plus de couleurs",//no i18n
	"crm.button.back.alone":"Retour",//no i18n
	"crm.field.label.email":"E-mail",//no i18n
"crm.zia.nba.feature.label":"Prochaine meilleure action",//no i18n
"Meeting":"R\u00e9union",//no i18n
"Tomorrow":"Demain",//no i18n
"crm.gdpr.notavailable.field":"Non disponible",//no i18n
	"crm.setup.system.ziasimilarity":"Recommandation de similarit\u00e9",//no i18n
	"crm.gdpr.notavailable.field":"Non disponible",//no i18n
	"crm.label.simply.in":"dans",//no i18n
	"crm.filter.label.all.products":"Tous les {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "\u00ab\u00a0Segment\u00a0\u00bb", // NO I18N
	"abm.segment.names": "\u00ab\u00a0Noms de segment\u00a0\u00bb", // NO I18N
	"abm.abm.segment.name": "\u00ab\u00a0Nom du segment ABM\u00a0\u00bb", // NO I18N
	"abm.segmentation.techniques": "\u00ab\u00a0Techniques de segmentation ABM\u00a0\u00bb", // NO I18N
	"abm.filters": "\u00ab\u00a0Filtres ABM\u00a0\u00bb", // NO I18N
	"abm.filters.info": "\u00ab\u00a0Ces filtres montreront les informations en fonction du {0} associ\u00e9 au {1}.\u00a0\u00bb", // NO I18N
	"abm.rfm.label": "\u00ab\u00a0Libell\u00e9 du RFM\u00a0\u00bb", // NO I18N
	"abm.firmographics.label": "\u00ab\u00a0Libell\u00e9 firmographiques\u00a0\u00bb" ,// NO I18N
	"abm.recommendation.label": "\u00ab\u00a0Libell\u00e9 de recommandation\u00a0\u00bb", // NO I18N
	"abm.voc.label": "\u00ab\u00a0Libell\u00e9 du VOC\u00a0\u00bb", // NO I18N
	"abm.engagement.label": "\u00ab\u00a0Libell\u00e9 d\u2019engagement\u00a0\u00bb", // NO I18N
	"abm.overall.score": "\u00ab\u00a0Score global\u00a0\u00bb", // NO I18N
	"abm.mood.score": "\u00ab\u00a0Score d\u2019humeur\u00a0\u00bb", // NO I18N
	"abm.value.score": "\u00ab\u00a0Score de valeur\u00a0\u00bb", // NO I18N
	"abm.engagement.score": "\u00ab\u00a0Score d\u2019engagement\u00a0\u00bb", // NO I18N
	"abm.rfm.score": "\u00ab\u00a0Score RFM\u00a0\u00bb", // NO I18N
	"crm.column.list.max.group.limit" : "Limite maximale autoris\u00e9e atteinte.", //no i18n
	"crm.customview.pin.column":"\u00c9pingler la colonne",//no i18n
	"crm.customview.unpin.column":"D\u00e9s\u00e9pingler la colonne",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} doit être supérieur ou égal à {1}.",
	"crux.users.selected.plural" : "{0} utilisateurs sélectionnés.",
	"crux.user.selected.singular" :"{0} utilisateur sélectionné.",
	"crux.criteria.empty.secondaryfield.module" : "Aucun champ {0} correspondant trouvé dans le module {1}",
	"crux.criteria.empty.secondaryfield" : "Aucun autre champ {0} disponible pour comparaison, veuillez saisir une valeur pour comparer.",
	"crux.logged.in.role.definition" : "Le rôle de l’utilisateur qui initie les actions d’enregistrement",
	"zia.similarity.smartfilter.score":"Score de similarit\u00e9",//no i18n
	"zia.similarity.smartfilter.records":"{0} similaire sur",//no i18n
	"zia.similarity.smartfilter.records.search":"Afficher {0} similaire sur",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Vous ne pouvez pas s\u00e9lectionner plus de {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} doit être supérieur ou égal à {1}.",
	"crux.users.selected.plural" : "{0} utilisateurs sélectionnés.",
	"crux.user.selected.singular" :"{0} utilisateur sélectionné.",
	"crux.criteria.empty.secondaryfield.module" : "Aucun champ {0} correspondant trouvé dans le module {1}",
	"crux.criteria.empty.secondaryfield" : "Aucun autre champ {0} disponible pour comparaison, veuillez saisir une valeur pour comparer.",
	"crux.logged.in.role.definition" : "Le rôle de l’utilisateur qui initie les actions d’enregistrement",
	"crux.max.limit.unselect" : "Vous ne pouvez pas déselectionner plus de {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u00ab\u00a0{0}\u00a0\u00bb est déjà sélectionné", //NO i18N
	"crm.label.true" : "Vrai",//NO I18N
	"crm.label.false" : "False",//NO I18N
	"crm.record.locked" : "Verrouill\u00e9",//NO I18N
	"crm.filter.number.range":"valeurs de {0}-{1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"Vous ne pouvez pas s\u00e9lectionner cette option pour plus de 10 valeurs s\u00e9lectionn\u00e9es.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"Vous ne pouvez pas s\u00e9lectionner plus de 5 {0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Vous ne pouvez pas s\u00e9lectionner plus de {0} {1}",//no i18n
	"crm.best.time.column.label":"Meilleur moment pour contacter",//no i18n
	"crm.competitoralert.mentioned.in.email":"Mentionn\u00e9 dans <b>l\u2019e-mail</b>",//no i18n
	"crm.competitoralert.with.sentiment":"Avec sentiment",//no i18n
	"crm.competitoralert.name.error":"Veuillez fournir une valeur valide pour le nom de comp\u00e9titeur.",//no i18n
	"crm.competitoralert.date.error":"Veuillez fournir une date valide pour la mention de comp\u00e9titeur dans l\u2019e-mail.",//no i18n
	"crm.competitoralert.sentiment.error":"Veuillez s\u00e9lectionner une option valide pour le sentiment de comp\u00e9titeur.",//no i18n
	"crm.competitor.name":"Concurrent",
"crm.lookup.advance.error.msg" : "Il y a eu un probl\u00e8me lors de l\u2019application du filtre. Actualisez la page et r\u00e9essayez.",
	"crm.label.add.tags":"Ajouter des balises",//no i18n
	"crm.confirm.deassociate":"D\u00e9sattribuer",//no i18n
	"Contact\ Name" : "Nom du {0}",
	"Lead\ Name" : "Nom de {0}",
	"-None-" : "Aucun",
	"January" : "Janvier",
	"February" : "F\u00e9vrier",
	"March" : "Mars",
	"April" : "Avril",
	"May" : "Mai",
	"June" : "Juin",
	"July" : "Juillet",
	"August" : "Ao\u00fbt",
	"September" : "Septembre",
	"October" : "Octobre",
	"November" : "Novembre",
	"December" : "D\u00e9cembre",
	// Image Upload Open
	"crm.label.browse.files":"Parcourir les fichiers",//no i18n
	"crm.label.lowercase.or":"ou",//no i18n
	"crm.image.header":"Image",//no i18n
	"crm.image.n.n":"Ratio N:N",//no i18n
	"crm.image.2.2":"Ratio 2:2",//no i18n
	"crm.image.4.3":"Ratio 4:3",//no i18n
	"crm.image.16.9":"Ratio 16:9",//no i18n
	"crm.image.height.width.swap":"Permuter la hauteur et la largeur",//no i18n
	"crm.image.rotate.image":"Faire pivoter",//no i18n
	"crm.label.lowercase.of":"de",//no i18n
	"crm.image.empty.name.placeholder" : "Saisir un nom",
	"crm.image.crop.and.rotate" : "Rogner et faire pivoter",
	"crm.image.width.px" : "px",
	"crm.image.description" : "Ajouter une description...",
	"crm.image.actual.size" : "Taille r\u00e9elle",
	"crm.image.reset" : "R\u00e9initialisation",
	"crm.view.attachment.download":"T\u00e9l\u00e9charger",//no i18n
	"crm.label.edit":"Modifier",//no i18n
	"crm.label.close":"Fermer",//no i18n
	"crm.label.any":"N\u2019importe quel",
	"crm.image.description":"Ajouter une description...",//no i18n
	"crm.image.zoom.in":"Zoom avant",//no i18n
	"crm.image.zoom.out":"Zoom arri\u00e8re",//no i18n
	"crm.label.lowercase.of":"de",//no i18n
	"crm.image.desc.maxsize":"La description ne doit pas d\u00e9passer 255\u00a0caract\u00e8res.",//no i18n
	"crm.image.name.maxsize":"Le nom ne doit pas d\u00e9passer 100 caract\u00e8res.",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"La taille totale du fichier d\u00e9passe la limite autoris\u00e9e de {0}.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"Vous pouvez t\u00e9l\u00e9charger un maximum de {0} fichiers seulement.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"Vous pouvez seulement joindre {0} fichiers au maximum.",//no i18n
	"crm.ImageuploadField.size.limit":"La taille totale de l\u2019image ou des images d\u00e9passe la limite autoris\u00e9e de {0}\u00a0Mo.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"Vous ne pouvez choisir qu\u2019une seule image",//no i18n
	"crm.image.crop.error":"Impossible de recadrer l\u2019image, veuillez r\u00e9essayer\u00a0!",//no i18n
	"crm.attach.here":"ici.",//no i18n
	"crm.image.unsupported.corrupted.single":"La photo que vous essayez de charger est endommag\u00e9e.",//no i18n
	"Browse":"Parcourir",//no i18n
	"crm.imageupload.failure":"Certaines images n\u2019ont pas pu \u00eatre charg\u00e9es. Veuillez r\u00e9essayer ult\u00e9rieurement.",//no i18n
	"zc.editor.width":"Largeur",//no i18n
	"ze.editor.height":"Hauteur",//no i18n
	"crm.label.delete":"Supprimer",//no i18n
	"crm.image.error.gif":"Une image GIF ne peut pas \u00eatre rogn\u00e9e.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} fichiers joints",//no i18n
	"crm.fileuploader.message.invalidfileType1":"Le fichier {0} n\u2019est pas pris en charge.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"Les fichiers {0} et {1} ne sont pas pris en charge.",//no i18n
	"crm.attach.upload.sizelimit":"Limite de la taille totale",//no i18n
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "Vous pouvez t\u00e9l\u00e9charger un maximum de {0} fichiers seulement.",
	"crm.file.upload.maxlength.exceeds" : "Vous pouvez charger uniquement un fichier dans {0}."


}
